button {
    border-radius: 5px;
    background: #000;
    width: 260px;
    height: 60px;
    flex-shrink: 0;
}

.button {
    padding-top: 5%;
    padding-bottom: 10%;
}

a { 
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
    text-decoration: none;
} 

.flex-column {
    display: flex; 
    flex-direction: row;
    justify-content: center;
}

.flex-row {
    display: flex; 
    flex-direction: column;
    justify-content: center;
}

.flex-box {
    display: flex; 
    flex-direction: row;
    justify-content: left;
}

.text-top {
    color: #000000;
    font-family: "Playfair Display";
    font-size: 800%;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding-top: 1%;
    filter: drop-shadow(2px 2px rgba(193,241,42,0.5))
}

.background {
    width: auto;
    height: auto;
    flex-shrink: 0;
    background: #F2F2F2;
    margin-top: 0%;
}

.text-grey {
    color: rgba(0, 0, 0, 0.50);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%;
    padding-top: 5%;
}

.text-description {
    color: #000;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%;
    padding-top: 5%;
}

.text-header {
    color: #000;
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.padding-text {
    padding-left: 2%;
}

.padding-image {
    padding-left: 8%;
    padding-right: 5%;
}

.padding-greybox {
    padding-top: 3%;
    padding-left: 7%;
}

.padding-socialMedia {
    padding-top: 1%;
}

@media (max-width: 600px) {
    .background {
        width: 300px;
        height: fit-content
    }

    .flex-box {
        display: grid;
        justify-content: center
    }

    .text-top {
        font-size: 500%
    }

    .padding-text {
        padding-left: 20%;
        padding-right: 20%;
    }

    .padding-image {
        padding-top: 10%;
        padding-left: 0%;
        padding-bottom: 10%;
        object-fit: contain;
    }
}